import styles from "./index.module.scss";

const CapesizeTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <ul>
          <li>ship name</li>
          <li>deadweight</li>
          <li>vessel type</li>
          <li>year built</li>
          <li>shipyard</li>
          <li>flag</li>
        </ul>
      </div>
      <div className={styles.tbody}>
        <ul>
          <li>Aquabridge</li>
          <li>177,106</li>
          <li>Capesize</li>
          <li>2005</li>
          <li>Namura</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquacharm</li>
          <li>182,309</li>
          <li>Capesize</li>
          <li>2022</li>
          <li>JMU</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Aquadonna </li>
          <li>177,173</li>
          <li>Capesize</li>
          <li>2005</li>
          <li>Namura</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquaenna</li>
          <li>175,975</li>
          <li>Capesize</li>
          <li>2011</li>
          <li>Jinhai</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquaexplorer</li>
          <li>178,929</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>Sungdong</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquagenie</li>
          <li>177,346</li>
          <li>Capesize</li>
          <li>2003</li>
          <li>Namura</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquahaha</li>
          <li>179,023</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>Hanjin (PH)</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquahope</li>
          <li>177,173</li>
          <li>Capesize</li>
          <li>2007</li>
          <li>Namura</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquakatie</li>
          <li>174,142</li>
          <li>Capesize</li>
          <li>2007</li>
          <li>SWS</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquamarie</li>
          <li>178,896</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>Sungdong</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquamarine</li>
          <li>182,060</li>
          <li>Capesize</li>
          <li>2009</li>
          <li>Odense</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquanavigator</li>
          <li>179,905</li>
          <li>Capesize</li>
          <li>2011</li>
          <li>Daehan</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquaproud</li>
          <li>178,057</li>
          <li>Capesize</li>
          <li>2009</li>
          <li>SWS</li>
          <li>SWS</li>
        </ul>
        <ul>
          <li>Aquarange</li>
          <li>179,842</li>
          <li>Capesize</li>
          <li>2011</li>
          <li>Hanjin (PH)</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquasalwador</li>
          <li>180,012</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>Daehan</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquataine</li>
          <li>181,725</li>
          <li>Capesize</li>
          <li>2010</li>
          <li>Imabari</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquatonka</li>
          <li>179,004</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>Hanjin (PH)</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquavictory</li>
          <li>182,060</li>
          <li>Capesize</li>
          <li>2010</li>
          <li>Odense</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquavoyageurs</li>
          <li>177,022</li>
          <li>Capesize</li>
          <li>2005</li>
          <li>Namura</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Athenian Phoenix</li>
          <li>179,222</li>
          <li>Capesize</li>
          <li>2009</li>
          <li>HHI</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Bulk China</li>
          <li>176,274</li>
          <li>Capesize</li>
          <li>2005</li>
          <li>Universal</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Corinthian Phoenix</li>
          <li>179,222</li>
          <li>Capesize</li>
          <li>2009</li>
          <li>HHI</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>MH Phoenix Beauty</li>
          <li>169,150</li>
          <li>Capesize</li>
          <li>2010</li>
          <li>Sungdong</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Nautical Dream</li>
          <li>180,730</li>
          <li>Capesize</li>
          <li>2013</li>
          <li>JMU</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Navios Pollux</li>
          <li>180,727</li>
          <li>Capesize</li>
          <li>2009</li>
          <li>STX</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>New York</li>
          <li>177,773</li>
          <li>Capesize</li>
          <li>2010</li>
          <li>SWS</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Philadelphia</li>
          <li>206,040</li>
          <li>Capesize</li>
          <li>2012</li>
          <li>SCH</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Semirio</li>
          <li>174,264</li>
          <li>Capesize</li>
          <li>2007</li>
          <li>SWS</li>
          <li>Marshall Isl.</li>
        </ul>
      </div>
    </div>
  );
};

export default CapesizeTable;
