import { useReducer } from "react";
import { createContext } from "react";
import MoveLogoReducer from "./MoveLogoReducer";

const INITIAL_STATE_MOVE_LOGO = { moveLogo: null };

export const MoveLogoContext = createContext(INITIAL_STATE_MOVE_LOGO);

export const MoveLogoProvider = ({ children }) => {
  const [stateMoveLogo, dispatchMoveLogo] = useReducer(MoveLogoReducer, INITIAL_STATE_MOVE_LOGO);

  const valueMoveLogoProvider = {
    moveLogo: stateMoveLogo.moveLogo,
    dispatchMoveLogo,
  };

  return <MoveLogoContext.Provider value={valueMoveLogoProvider}>{children}</MoveLogoContext.Provider>;
};
