import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import styles from "./index.module.scss";

const useInViewOptions = {
  threshold: 0,
  rootMargin: "0% 0px -29.9% 0px",
};

const Company1b = () => {
  const mobileAndTablet = useMediaQuery({ query: "(max-width: 1365px)" });
  const videoRef = useRef(null);
  const { ref: sectionVideoRef, inView: toggleVideoPlay } = useInView(useInViewOptions);

  useEffect(() => {
    if (!videoRef.current) return;

    if (toggleVideoPlay) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [toggleVideoPlay]);

  return (
    <section ref={sectionVideoRef} className={styles.container + " container"}>
      <div className={styles.container__inner}>
        <div className={styles.container__content}>
          <h4 className={styles.container__content_name}>Meet our new project</h4>
          <div className={styles.container__content_block}>
            <img className={styles.container__content_img} src="/content/images/about/company1b/company1b-logo.svg" alt="company1b-logo" />
            <div className={styles.container__content_item}>
              <p className={styles.container__content_description}>
                The port management
                <br />
                and development company
              </p>
              <a className={styles.container__content_button} href="https://1b.xyz" target="_blanck">
                VISIT SITE
              </a>
            </div>
          </div>
        </div>
        <div className={styles.container__video}>
          <video
            ref={videoRef}
            className={styles.container__video_item}
            src={mobileAndTablet ? "/content/video/company1b/company1b_360.mov" : "/content/video/company1b/company1b_720.mov"}
            preload="metadata"
            muted
            autoPlay
            loop
            playsInline
          ></video>
        </div>
      </div>
    </section>
  );
};

export default Company1b;
