import styles from "./index.module.scss";

const HandymaxTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <ul>
          <li>ship name</li>
          <li>deadweight</li>
          <li>vessel type</li>
          <li>year built</li>
          <li>shipyard</li>
          <li>flag</li>
        </ul>
      </div>
      <div className={styles.tbody}>
        <ul>
          <li>Four Aida</li>
          <li>34,370</li>
          <li>Handymax</li>
          <li>2009</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Butterfly</li>
          <li>34,471</li>
          <li>Handymax</li>
          <li>2011</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Diamond</li>
          <li>34,059</li>
          <li>Handymax</li>
          <li>2011</li>
          <li>VINASHIN PHARUNG</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Emerald</li>
          <li>33,992</li>
          <li>Handymax</li>
          <li>33,992</li>
          <li>VINASHIN PHARUNG</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Nabucco</li>
          <li>34,404</li>
          <li>Handymax</li>
          <li>2010</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Otello</li>
          <li>34,357</li>
          <li>Handymax</li>
          <li>2010</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Rigoletto</li>
          <li>34,437</li>
          <li>Handymax</li>
          <li>2011</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Four Turandot</li>
          <li>34,428</li>
          <li>Handymax</li>
          <li>2012</li>
          <li>SPP</li>
          <li>Malta</li>
        </ul>
      </div>
    </div>
  );
};

export default HandymaxTable;
