import { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import styles from "./index.module.scss";
import { usePageTransitionContext } from "../../hook/usePageTransitionContext";

const Transition = () => {
  const { pageTransitionAnimation, dispatchPageTransition } = usePageTransitionContext();

  const transitionWrapper = useRef(null);
  const transitionBackground = useRef(null);
  const transitionContainer = useRef(null);

  useEffect(() => {
    if (pageTransitionAnimation === null) {
      transitionWrapper.current.style.display = "none";
      gsap.set([transitionBackground.current, transitionContainer.current], { height: "0%", skewY: 2 });
    } else if (pageTransitionAnimation === false) {
      transitionWrapper.current.style.display = "none";
      gsap.to(
        [transitionContainer.current, transitionBackground.current],
        {
          // duration: 1,
          y: 0,
          ease: "power3.inOut",
          transformOrigin: "bottom left",
          height: "0%",
          minHeight: "0%",
          skewY: 2,
          stagger: {
            amount: 0.1,
          },
        },
        "-=0.5"
      );
    } else if (pageTransitionAnimation === true) {
      transitionWrapper.current.style.display = "block";
      setTimeout(() => {
        dispatchPageTransition({ type: "pageTransitionHidden" });
      }, 1000);

      function activeTransition() {
        const timeline = gsap.timeline({
          defaults: {
            duration: 0.8,
            stagger: {
              amount: 0.1,
            },
          },
        });
        timeline.to(
          [transitionContainer.current, transitionBackground.current],
          {
            ease: "power3.inOut",
            transformOrigin: "bottom left",
            height: "0%",
            minHeight: "0%",
            skewY: 2,
          },
          "-=0.5"
        );
        timeline.to([transitionBackground.current, transitionContainer.current], {
          ease: "power3.inOut",
          transformOrigin: "bottom left",
          height: "100%",
          minHeight: "100%",
          skewY: 0,
        });
        timeline.to([transitionBackground.current, transitionContainer.current], {
          ease: "power3.inOut",
          delay: 0.2,
          y: "-100%",
        });
        timeline.to(
          [transitionContainer.current, transitionBackground.current],
          {
            y: 0,
            duration: 0,
            ease: "power3.inOut",
            transformOrigin: "bottom left",
            height: "0%",
            minHeight: "0%",
            skewY: 2,
          },
          "-=0.5"
        );

        return () => timeline.kill();
      }
      activeTransition();
    }
  }, [pageTransitionAnimation]);

  return (
    <>
      {/* <div ref={transitionWrapper} className={letsGo ? [styles.transitionWrapper, styles.letsGo].join(" ") : styles.transitionWrapper}></div> */}
      <div ref={transitionWrapper} className={styles.transitionWrapper}></div>
      <div ref={transitionBackground} className={styles.background}></div>
      <div ref={transitionContainer} className={styles.transitionContainer}></div>
    </>
  );
};

export default Transition;
