import styles from "./index.module.scss";

const NewcastlemaxTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <ul>
          <li>ship name</li>
          <li>deadweight</li>
          <li>vessel type</li>
          <li>year built</li>
          <li>shipyard</li>
          <li>flag</li>
        </ul>
      </div>
      <div className={styles.tbody}>
        <ul>
          <li>MP The Bruschi</li>
          <li>208,214</li>
          <li>Newcastlemax</li>
          <li>2020</li>
          <li>Jiangsu Yangzi Jiang</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>MP The Harrison</li>
          <li>208,283</li>
          <li>Newcastlemax</li>
          <li>2021</li>
          <li>Jiangsu Yangzi Jiang</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>MP The Vinatieri</li>
          <li>208,283</li>
          <li>Newcastlemax</li>
          <li>2021</li>
          <li>Jiangsu Yangzi Jiang</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>MP The Vrabel</li>
          <li>208,285</li>
          <li>Newcastlemax</li>
          <li>2015</li>
          <li>Jiangsu Yangzi Jiang</li>
          <li>Liberia</li>
        </ul>
      </div>
    </div>
  );
};

export default NewcastlemaxTable;
