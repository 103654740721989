import { useEffect, useRef, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useMoveLogoContext } from "../../hook/useMoveLogoContext";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";
import styles from "./index.module.scss";

const ScrollForMore = (attr) => {
  const { moveLogo, dispatchMoveLogo } = useMoveLogoContext();
  const { ref: logoRef, inView: moveLogoView } = useInView({ threshold: 0.1 });
  const isTablet = useMediaQuery({ query: "(max-width: 1365px)" });
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("moveLogo " + moveLogo);
    if (pathname !== "/about") {
      if (moveLogoView && !isTablet) {
        dispatchMoveLogo({ type: "heroVisible" });
      } else {
        dispatchMoveLogo({ type: "heroHidden" });
      }
    } else {
      // dispatchMoveLogo({ type: "heroHidden" });
    }
  }, [moveLogoView]);

  return (
    <div ref={logoRef} {...attr} className={styles.container}>
      <img className={styles.arrow} src="/content/icons/arrow-down-white.svg" alt="no-content" />
      <img className={styles.text} src="/content/icons/scroll-more-text.svg" alt="no-content" />
    </div>
  );
};

export default ScrollForMore;
