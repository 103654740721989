import { useEffect } from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../hook/usePageTransitionContext";
import ScrollForMore from "../../components/ScrollForMore";
import { detect } from "detect-browser";
import styles from "./index.module.scss";

const Fleets = () => {
  const { dispatchPageTransition } = usePageTransitionContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();
  const isSafari = detect().name === "safari";

  useEffect(() => {
    if (pathname === "/fleets") {
      navigate("/fleets/capesize", { replace: true });
      setTimeout(() => scroll.scrollTo(0, { duration: 0 }));
    } else {
      scroll?.scrollTo(0, { duration: 0 });
    }
  }, []);

  return (
    <div className={styles.fleets}>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate("/about"), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Fleet</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#tabs", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <img src="/content/images/fleets-page.jpg" alt="content" />
        </div>
      </div>
      <div id="tabs" className={styles.tabs + " container"}>
        <h3>Vessels by Type</h3>
        <ul>
          <li>
            <NavLink to="capesize" className={({ isActive }) => (isActive ? styles.activeLink + " " + styles.link : styles.link)}>
              capesize
            </NavLink>
          </li>
          <li>
            <NavLink to="panamax" className={({ isActive }) => (isActive ? styles.activeLink + " " + styles.link : styles.link)}>
              panamax
            </NavLink>
          </li>
          <li>
            <NavLink to="supramax" className={({ isActive }) => (isActive ? styles.activeLink + " " + styles.link : styles.link)}>
              supramax
            </NavLink>
          </li>
          <li>
            <NavLink to="handymax" className={({ isActive }) => (isActive ? styles.activeLink + " " + styles.link : styles.link)}>
              handymax
            </NavLink>
          </li>
          <li>
            <NavLink to="newcastlemax" className={({ isActive }) => (isActive ? styles.activeLink + " " + styles.link : styles.link)}>
              newcastlemax
            </NavLink>
          </li>
        </ul>
      </div>
      <div className={styles.tableContainer + " container"}>
        <Outlet />
      </div>
    </div>
  );
};

export default Fleets;
