import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../../hook/usePageTransitionContext";
import { gsap, Power2, Bounce } from "gsap";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollForMore from "../../../components/ScrollForMore";
import CountUp, { useCountUp } from "react-countup";
import styles from "../index.module.scss";

const CapesizeDetails = () => {
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();

  const { dispatchPageTransition } = usePageTransitionContext();

  const containerRef = useRef(null);

  const [startCounterFact, setStartCounterFact] = useState(false);
  const factsRef = useRef(null);
  const factsContainerRef = useRef(null);
  const factsBlock1Ref = useRef(null);

  const flexibilityRef = useRef(null);
  const flexibilityTitleRef = useRef(null);
  const flexibilityBlock1Ref = useRef(null);
  const flexibilityBlock2Ref = useRef(null);
  const flexibilityBlock3Ref = useRef(null);

  const approachContainerRef = useRef(null);
  const approachBlock1Ref = useRef(null);
  const approachBlock2Ref = useRef(null);
  const approachBlock3Ref = useRef(null);
  const approachBlock4Ref = useRef(null);
  const approachBlock5Ref = useRef(null);
  const approachBlock6Ref = useRef(null);

  useEffect(() => {
    if (scroll) setTimeout(() => scroll.scrollTo(0, { duration: 0 }), 100);
  }, []);

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;
      let pinWrap = factsContainerRef.current;
      let pinWrapWidth = pinWrap.offsetWidth;
      let horizontalScrollLength = pinWrapWidth - window.innerWidth;

      gsap.to(pinWrap, {
        scrollTrigger: {
          trigger: factsRef.current,
          scroller: element,
          scrub: true,
          pin: true,
          start: "top top",
          end: () => `+=${pinWrapWidth}`,
          invalidateOnRefresh: true,
        },
        x: -horizontalScrollLength,
        ease: "none",
      });

      setTimeout(() => {
        gsap.to(pinWrap, {
          scrollTrigger: {
            trigger: factsBlock1Ref.current,
            scroller: element,
            onEnter: () => {
              setStartCounterFact(!startCounterFact);
            },
          },
        });
      }, 1000);

      function flexibilityAnimation() {
        let tl = gsap.timeline({
          defaults: {
            // duration: 4,
            x: 0,
            scale: 1,
            rotation: 0,
            force3D: true,
            // ease: Power2,
            ease: Bounce,
            opacity: 1,
            skewX: 0,
          },
          scrollTrigger: {
            trigger: flexibilityRef?.current,
            scroller: element,
            start: "-50% top",
            end: "bottom bottom",
            invalidateOnRefresh: true,
          },
        });
        tl.to(flexibilityBlock1Ref?.current, {});
        tl.to(flexibilityBlock2Ref?.current, {});
        tl.to(flexibilityBlock3Ref?.current, {});

        return () => tl.scrollTrigger.kill();
      }
      flexibilityAnimation();

      function approachAnimation() {
        let tl = gsap.timeline({
          defaults: {
            // duration: 10,
            y: 0,
            scale: 1,
            // boxShadow: "-1px -18px 25px -26px rgba(0,0,0,0.84)",
            force3D: true,
            ease: Bounce,
          },
          scrollTrigger: {
            trigger: approachContainerRef.current,
            scroller: element,
            scrub: true,
            end: () => `+=${containerRef.current.offsetHeight}`,
            pin: true,
            start: "center center",
            invalidateOnRefresh: true,
          },
        });
        tl.to(approachBlock1Ref.current, {});
        tl.to(approachBlock2Ref.current, {});
        tl.to(approachBlock3Ref.current, {});
        tl.to(approachBlock4Ref.current, {});
        tl.to(approachBlock5Ref.current, {});
        // tl.to(approachBlock6Ref.current, { boxShadow: "-3px 34px 30px -3px rgba(0,0,0,0.3)", scale: 1 });
        tl.to(approachBlock6Ref.current, {});

        return () => tl.scrollTrigger.kill();
      }
      approachAnimation();
      ScrollTrigger.refresh();
    }
  }, [scroll]);

  return (
    <>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate("/about"), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Capesize RSA</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#containerCapesizeDetails", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <picture>
            <source media="(max-width: 767px)" srcSet="/content/images/secondary_pages/pools/mobile/3.jpg" alt="content"></source>
            <source media="(max-width: 1919px)" srcSet="/content/images/secondary_pages/pools/laptop/3.jpg" alt="content"></source>
            <source media="(max-width: 2559px)" srcSet="/content/images/secondary_pages/pools/desctop/3.jpg" alt="content"></source>
            <source media="(max-width: 3839px)" srcSet="/content/images/secondary_pages/pools/2k/3.jpg" alt="content"></source>
            <img src="/content/images/secondary_pages/pools/4k/3.jpg" alt="content" />
          </picture>
        </div>
      </div>
      <div id="containerCapesizeDetails" ref={factsRef} className={styles.facts}>
        <div ref={factsContainerRef} className={styles.facts_container}>
          <h3>Facts & Figures</h3>
          <ul>
            <li ref={factsBlock1Ref}>
              <div>
                <img src="/content/icons/supramax-weight.svg" alt="weight" />
              </div>
              <div>
                <h4>{startCounterFact && <CountUp start={0.0} end={3.7} duration={1} separator=" " decimals={1} decimal="," />} million</h4>
                <span>total DWT</span>
              </div>
            </li>
            <li>
              <div>
                <img src="/content/icons/capesize-ship.svg" alt="ship" />
              </div>
              <div>
                <h4>{startCounterFact && <CountUp start={0.0} end={29} duration={2} separator=" " />}</h4>
                <span>Vessels</span>
              </div>
            </li>
            <li>
              <div>
                <img src="/content/icons/supramax-weight.svg" alt="weight" />
              </div>
              <div>
                <h4>{startCounterFact && <CountUp start={0.0} end={3.7} duration={3} separator=" " decimals={1} decimal="," />} m</h4>
                <span>Average DWT</span>
              </div>
            </li>
            <li>
              <div>
                <img src="/content/icons/supramax-size.svg" alt="size" />
              </div>
              <div>
                <h4>
                  {" "}
                  {startCounterFact && <CountUp start={0.0} end={76.0} duration={4} separator=" " decimals={3} decimal="," />} -{" "}
                  {startCounterFact && <CountUp start={0.0} end={95.0} duration={4} separator=" " decimals={3} decimal="," />}
                </h4>
                <span>Vessel Sizes</span>
              </div>
            </li>
            <li>
              <div>
                <img src="/content/icons/supramax-calendar.svg" alt="weight" />
              </div>
              <div>
                <h4>{startCounterFact && <CountUp start={0.0} end={9} duration={5} separator=" " decimals={1} decimal="," />} Years</h4>
                <span>Average Age</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div ref={containerRef}>
        <div ref={flexibilityRef} className={styles.flexibility + " " + "container"}>
          <div className={styles.flexibility_container}>
            <h3 ref={flexibilityTitleRef}>Flexibility without unnecessary Costs</h3>
            <ul>
              <li ref={flexibilityBlock1Ref}>
                <div>
                  <img src="/content/icons/services/done-list.svg" alt="done-list" />
                </div>
                <p>No Daily Administration Fees are charged to the members of the RSA, only a 1.25% commission.</p>
              </li>
              <li ref={flexibilityBlock2Ref}>
                <div>
                  <img src="/content/icons/services/done-list.svg" alt="done-list" />
                </div>
                <span>NO withdrawal fees</span>
              </li>
              <li ref={flexibilityBlock3Ref}>
                <div>
                  <img src="/content/icons/services/done-list.svg" alt="done-list" />
                </div>
                <span>Flexible 3 months exit notice</span>
              </li>
            </ul>
          </div>
        </div>
        <div ref={approachContainerRef} className={styles.approach + " container"}>
          <div className={styles.approach_container}>
            <h3>Approach</h3>
            <ul>
              <li ref={approachBlock1Ref}>
                <div>
                  <img src="/content/icons/approach/star.svg" alt="star" />
                </div>
                <div>
                  <h4>Experience</h4>
                  <p>Long-standing industry contacts and experience with the major operators and charterers</p>
                </div>
              </li>
              <li ref={approachBlock2Ref}>
                <div>
                  <img src="/content/icons/approach/time.svg" alt="time" />
                </div>
                <div>
                  <h4>Minimizing Idle time</h4>
                  <p>Minimizing Idle time and repositioning expenses</p>
                </div>
              </li>
              <li ref={approachBlock3Ref}>
                <div>
                  <img src="/content/icons/approach/vector-go.svg" alt="vector-go" />
                </div>
                <div>
                  <h4>Market Data</h4>
                  <p>Access to wide ranging market analysis</p>
                </div>
              </li>
              <li ref={approachBlock4Ref}>
                <div>
                  <img src="/content/icons/approach/dollar.svg" alt="dollar" />
                </div>
                <div>
                  <h4>Economies of Scale</h4>
                  <p>Economies of Scale {"->"} cost reductions More Vessels = Increased information and more opportunities</p>
                </div>
              </li>
              <li ref={approachBlock5Ref}>
                <div>
                  <img src="/content/icons/approach/less_ballast.svg" alt="less_ballast" />
                </div>
                <div>
                  <h4>Less Ballast Voyages</h4>
                </div>
              </li>
              <li ref={approachBlock6Ref}>
                <div>
                  <img src="/content/icons/approach/time.svg" alt="time" />
                </div>
                <div>
                  <h4>Shorter spread between loading dates</h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapesizeDetails;
