import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../../hook/usePageTransitionContext";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { gsap, Power2, Bounce } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import IceAgeSubLogo from "../../../components/IceAgeSubLogo";
import ScrollForMore from "../../../components/ScrollForMore";
import styles from "../index.module.scss";

const Commercial = () => {
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();

  const { dispatchPageTransition } = usePageTransitionContext();

  const contentRef = useRef(null);
  const contentBlock1Ref = useRef(null);
  const contentBlock2Ref = useRef(null);
  const contentBlock3Ref = useRef(null);
  const contentBlock4Ref = useRef(null);

  const subtitleRef = useRef(null);

  useEffect(() => {
    if (scroll) setTimeout(() => scroll.scrollTo(0, { duration: 0 }), 100);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;

      function processAnimation() {
        let tl = gsap.timeline({
          defaults: {
            x: 0,
            y: 0,
            skewX: 0,
            duration: 1,
          },
          scrollTrigger: {
            trigger: contentRef.current,
            scroller: element,
            start: "-50% top",
            // end: () => `+=${contentRef.current.offsetHeight}`,
            end: "bottom bottom",
            // scrub: true,
            // pin: true,
            toggleActions: "play pouse none none",
            invalidateOnRefresh: true,
          },
        });

        tl.to(contentBlock1Ref.current, {});
        tl.to(contentBlock2Ref.current, {});
        tl.to(contentBlock3Ref.current, {});
        tl.to(contentBlock4Ref.current, {});

        return () => tl.kill();
      }
      processAnimation();
    }
    ScrollTrigger.refresh();
  }, [scroll]);

  return (
    <>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate(-1), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Commercial Service</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#subtitleCommercial", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <picture>
            <source media="(max-width: 767px)" srcSet="/content/images/secondary_pages/services/mobile/1.jpg" alt="content"></source>
            <source media="(max-width: 1919px)" srcSet="/content/images/secondary_pages/services/laptop/1.jpg" alt="content"></source>
            <source media="(max-width: 2559px)" srcSet="/content/images/secondary_pages/services/desctop/1.jpg" alt="content"></source>
            <source media="(max-width: 3839px)" srcSet="/content/images/secondary_pages/services/2k/1.jpg" alt="content"></source>
            <img src="/content/images/secondary_pages/services/4k/1.jpg" alt="content" />
          </picture>
        </div>
      </div>
      <div ref={subtitleRef} id="subtitleCommercial" className={styles.subtitle}>
        <p>
          We are commercial management team has over <span>100 years</span> of experience. This is in part how we continue to be successful and how we
          have chosen to operate in the market
        </p>
        <IceAgeSubLogo />
      </div>
      <div ref={contentRef} className={styles.content + " container"}>
        <div className={styles.content_container}>
          <article ref={contentBlock1Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/chartering.svg')" }}></div>
            <h6>Chartering</h6>
            <p>
              Utilising our knowledge of the marketplace with due diligence, we seek to maximise returns whilst reducing any risk before deciding on a
              Time or Voyage charter for vessels under our management
            </p>
          </article>
          <article ref={contentBlock2Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/forward.svg')" }}></div>
            <h6>Forward Freight Agreements</h6>
            <p>
              We are been trading Forward Freight Agreements (FFA’s) since 2004. Our activity on the FFA’s has made us one of the most active groups
              in this area
            </p>
          </article>
          <article ref={contentBlock3Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/sale.svg')" }}></div>
            <h6>Sale & Purchase</h6>
            <p>
              With Ice Age years of experience in getting the best value for our clients, we have built up close relationships with both shipyards and
              brokers across the world
            </p>
          </article>
          <article ref={contentBlock4Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/tanker.svg')" }}></div>
            <h6>Tanker Management</h6>
            <p>
              Ice Age has been overseeing the commercial operations of its tankers since 2007 when the Radziwill family entered into a joint venture
              with Euronav on a number of Suezmaxes
            </p>
          </article>
        </div>
      </div>
    </>
  );
};

export default Commercial;
