import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { usePageTransitionContext } from "../../../hook/usePageTransitionContext";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { gsap, Power2, Bounce } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import IceAgeSubLogo from "../../../components/IceAgeSubLogo";
import ScrollForMore from "../../../components/ScrollForMore";
import styles from "../index.module.scss";

const Technical = () => {
  const navigate = useNavigate();
  const { scroll } = useLocomotiveScroll();

  const { dispatchPageTransition } = usePageTransitionContext();

  const contentRef = useRef(null);
  const contentBlock1Ref = useRef(null);
  const contentBlock2Ref = useRef(null);
  const contentBlock3Ref = useRef(null);
  const contentBlock4Ref = useRef(null);

  const subtitleRef = useRef(null);

  useEffect(() => {
    if (scroll) setTimeout(() => scroll.scrollTo(0, { duration: 0 }), 100);
  }, []);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (scroll) {
      const element = scroll?.el;

      function processAnimation() {
        let tl = gsap.timeline({
          defaults: {
            x: 0,
            y: 0,
            skewX: 0,
          },
          scrollTrigger: {
            trigger: subtitleRef.current,
            scroller: element,
            start: "center center",
            end: () => `+=${contentRef.current.offsetHeight}`,
            // scrub: true,
            // pin: true,
            invalidateOnRefresh: true,
            toggleActions: "play pouse none none",
          },
        });

        tl.to(contentBlock1Ref.current, {});
        tl.to(contentBlock2Ref.current, {});
        tl.to(contentBlock3Ref.current, {});
        tl.to(contentBlock4Ref.current, {});

        return () => tl.kill();
      }
      processAnimation();
      ScrollTrigger.refresh();
    }
  }, [scroll]);

  return (
    <>
      <button
        className={styles.back}
        onClick={() => {
          dispatchPageTransition({ type: "pageTransitionVisible" });
          setTimeout(() => navigate(-1), 1000);
        }}
      ></button>
      <div className={styles.hero}>
        <div className={styles.left}>
          <img className={styles.logotype} src="/content/icons/logotype.svg" alt="logotype" />
          <div className={styles.left_title}>
            <h2>Technical Service</h2>
          </div>
          <ScrollForMore onClick={() => scroll.scrollTo("#subtitleTechnical", { duration: 1 })} />
        </div>
        <div className={styles.right}>
          <picture>
            <source media="(max-width: 767px)" srcSet="/content/images/secondary_pages/services/mobile/3.jpg" alt="content"></source>
            <source media="(max-width: 1919px)" srcSet="/content/images/secondary_pages/services/laptop/3.jpg" alt="content"></source>
            <source media="(max-width: 2559px)" srcSet="/content/images/secondary_pages/services/desctop/3.jpg" alt="content"></source>
            <source media="(max-width: 3839px)" srcSet="/content/images/secondary_pages/services/2k/3.jpg" alt="content"></source>
            <img src="/content/images/secondary_pages/services/4k/3.jpg" alt="content" />
          </picture>
        </div>
      </div>
      <div ref={subtitleRef} id="subtitleTechnical" className={styles.subtitle}>
        <p>
          ICe Age’s Technical Management team is responsible for ensuring the seaworthiness of all vessels at all times and maintaining them to the
          highest possible quality standards at minimum operational cost
        </p>
        <IceAgeSubLogo />
      </div>
      <div ref={contentRef} className={styles.content + " container"}>
        <div className={styles.content_container}>
          <article ref={contentBlock1Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/support.svg')" }}></div>
            <h6>New Building Support</h6>
            <p>
              Our team provides all aspects of new building support including contract drafting, specification review, plan approvals and shipbuilding
              supervision
            </p>
          </article>
          <article ref={contentBlock2Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/calendar.svg')" }}></div>
            <h6>Conversions & Modifications</h6>
            <p>
              Our team is always keeping abreast with evolving regulations. Ensuring that when they come into force, we'll notified the owners of the
              vessels ahead of time, and created a planning schedule
            </p>
          </article>
          <article ref={contentBlock3Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/sale.svg')" }}></div>
            <h6>Sale & Purchase</h6>
            <p>
              We conduct all surveys and inspections for the sale and purchase to ensure that our clients are fully up to speed with a vessel’s
              condition
            </p>
          </article>
          <article ref={contentBlock4Ref}>
            <div style={{ backgroundImage: "url('/content/icons/services/safety.svg')" }}></div>
            <h6>Safety & Maintenance</h6>
            <p>
              Our team of highly skilled superintendents spend an average of 4 months a year on location with our clients’ vessels, covering all
              aspects of ship maintenance and surveys
            </p>
          </article>
        </div>
      </div>
    </>
  );
};

export default Technical;
