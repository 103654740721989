import styles from './index.module.scss';

const IceAgeSubLogo = () => {
  return (
    <div className={styles.container}>
      <img className={styles.text} src="/content/icons/ice-age-logo-text.svg" alt="logotype text" />
      <img className={styles.image} src="/content/icons/ice-age-logo-image.svg" alt="logotype img" />
    </div>
  );
};

export default IceAgeSubLogo;
