import styles from "./index.module.scss";

const SupramaxTable = () => {
  return (
    <div className={styles.table}>
      <div className={styles.thead}>
        <ul>
          <li>ship name</li>
          <li>deadweight</li>
          <li>vessel type</li>
          <li>year built</li>
          <li>shipyard</li>
          <li>flag</li>
        </ul>
      </div>
      <div className={styles.tbody}>
        <ul>
          <li>Alis</li>
          <li>57,981</li>
          <li>Supramax</li>
          <li>2013</li>
          <li>Zhejiang</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Amis Kalon</li>
          <li>58,107</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>Tsuneishi Zoushan</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Amis Star</li>
          <li>61,123</li>
          <li>Supramax</li>
          <li>2019</li>
          <li>DACKS</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Amis Wisdom I</li>
          <li>61,611</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>Oshima</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Annegrit</li>
          <li>61,208</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Onomichi</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>Aquaprosper</li>
          <li>61,208</li>
          <li>Supramax</li>
          <li>2015</li>
          <li>Imabari</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Aquitania</li>
          <li>55,932</li>
          <li>Supramax</li>
          <li>2006</li>
          <li>MES</li>
          <li>UK</li>
        </ul>
        <ul>
          <li>BBG Leader</li>
          <li>63,241</li>
          <li>Supramax</li>
          <li>2015</li>
          <li>Chengxi</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Belinda</li>
          <li>63,338</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Hantong</li>
          <li>Norway</li>
        </ul>
        <ul>
          <li>Bulk Bolivia</li>
          <li>63,465</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Imabari</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Bulk Guatemala</li>
          <li>61,446</li>
          <li>Supramax</li>
          <li>2013</li>
          <li>Imabari</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Bulk Manara</li>
          <li>55,692</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>MES</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>CL Anzi He</li>
          <li>63,077</li>
          <li>Supramax</li>
          <li>2020</li>
          <li>New Dayang Shipbuilding</li>
          <li>Hong Kong</li>
        </ul>
        <ul>
          <li>CL Anzi He</li>
          <li>63,077</li>
          <li>Supramax</li>
          <li>2020</li>
          <li>New Dayang Shipbuilding</li>
          <li>Hong Kong</li>
        </ul>
        <ul>
          <li>CMB Bruegel</li>
          <li>63,667</li>
          <li>Supramax</li>
          <li>2021</li>
          <li>Shin Kasado</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CMB Chikako</li>
          <li>61,229</li>
          <li>Supramax</li>
          <li>2014</li>
          <li>Imabari</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CMB Floris</li>
          <li>63,628</li>
          <li>Supramax</li>
          <li>2021</li>
          <li>Tadotsu Shipyard</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>CMB Jordaens</li>
          <li>63,447</li>
          <li>Supramax</li>
          <li>2019</li>
          <li>Imabari</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CMB Matsys</li>
          <li>63,620</li>
          <li>Supramax</li>
          <li>2021</li>
          <li>Shin Kasado</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CMB Rubens</li>
          <li>63,514</li>
          <li>Supramax</li>
          <li>2018</li>
          <li>Imabari</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>CMB Teniers</li>
          <li>63,611</li>
          <li>Supramax</li>
          <li>2021</li>
          <li>Shin Kasado</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CMB Van Dijck</li>
          <li>65,395</li>
          <li>Supramax</li>
          <li>2020</li>
          <li>Shin Kasado</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>CS Sonoma</li>
          <li>56,704</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>Jiangsu Hantong</li>
          <li>Bahamas</li>
        </ul>
        <ul>
          <li>Dolce Vita</li>
          <li>61,616</li>
          <li>Supramax</li>
          <li>2012</li>
          <li>Oshima</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>E.R Bristol</li>
          <li>55,659</li>
          <li>Supramax</li>
          <li>2011</li>
          <li>Hyundai Mipo</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>E.R. Bordeaux</li>
          <li>55,621</li>
          <li>Supramax</li>
          <li>2011</li>
          <li>Hyundai Mipo</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>FJ Star</li>
          <li>61,225</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Toyohashi</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>FJ Viola</li>
          <li>61,174</li>
          <li>Supramax</li>
          <li>2020</li>
          <li>DACKS</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Golden Cecilie</li>
          <li>60,263</li>
          <li>Supramax</li>
          <li>2015</li>
          <li>JMU</li>
          <li>Hong Kong</li>
        </ul>
        <ul>
          <li>Golden Glint</li>
          <li>61,297</li>
          <li>Supramax</li>
          <li>2015</li>
          <li>Imabari</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Ionic Spirit</li>
          <li>56,119</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>MES</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Marshall Isl.</li>
          <li>60,425</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>MES</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>John Oldendorff</li>
          <li>61,579</li>
          <li>Supramax</li>
          <li>2019</li>
          <li>Nantong COSCO</li>
          <li>Portugal</li>
        </ul>
        <ul>
          <li>KN Fortune</li>
          <li>61,028</li>
          <li>Supramax</li>
          <li>2020</li>
          <li>DACKS Dalian</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>Maple Harbour</li>
          <li>53,832</li>
          <li>Supramax</li>
          <li>2011</li>
          <li>IHI</li>
          <li>South Korea</li>
        </ul>
        <ul>
          <li>Medi Aero</li>
          <li>57,517</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Tsuneishi</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Medi Brisbane</li>
          <li>60,336</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Mitsui</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Medi Norfolk</li>
          <li>60,384</li>
          <li>Supramax</li>
          <li>2019</li>
          <li>MES</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Nautical Madison</li>
          <li>63,371</li>
          <li>Supramax</li>
          <li>2018</li>
          <li>Jiangsu Hantong</li>
          <li>Liberia</li>
        </ul>
        <ul>
          <li>Navios Amaryllis</li>
          <li>58,735</li>
          <li>Supramax</li>
          <li>2008</li>
          <li>Tsuneishi Zhoushan</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Nefeli</li>
          <li>63,466</li>
          <li>Supramax</li>
          <li>2016</li>
          <li>Jinglu</li>
          <li>Malta</li>
        </ul>
        <ul>
          <li>Red Azalea</li>
          <li>61,299</li>
          <li>Supramax</li>
          <li>2015</li>
          <li>Imabari</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Red Sakura</li>
          <li>60,245</li>
          <li>Supramax</li>
          <li>2017</li>
          <li>Oshima</li>
          <li>Singapore</li>
        </ul>
        <ul>
          <li>SFL Yukon</li>
          <li>56,869</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>Xiamen</li>
          <li>Hong Kong</li>
        </ul>
        <ul>
          <li>ST George</li>
          <li>57,959</li>
          <li>Supramax</li>
          <li>2012</li>
          <li>Tsuneishi Cebu</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>ST Paul</li>
          <li>57,982</li>
          <li>Supramax</li>
          <li>2010</li>
          <li>Tsuneishi Cebu</li>
          <li>Hong Kong</li>
        </ul>
        <ul>
          <li>ST Pinot</li>
          <li>57,949</li>
          <li>Supramax</li>
          <li>2013</li>
          <li>Tsuneishi Cebu</li>
          <li>Marshall Isl.</li>
        </ul>
        <ul>
          <li>Unity Endeavour</li>
          <li>61,617</li>
          <li>Supramax</li>
          <li>2014</li>
          <li>NACKS</li>
          <li>Bahamas</li>
        </ul>
        <ul>
          <li>Victoria T</li>
          <li>61,266</li>
          <li>Supramax</li>
          <li>2017</li>
          <li>Toyohashi</li>
          <li>Panama</li>
        </ul>
        <ul>
          <li>Wariya Naree</li>
          <li>53,833</li>
          <li>Supramax</li>
          <li>2011</li>
          <li>Visakhapatnam</li>
          <li>Thailand</li>
        </ul>
      </div>
    </div>
  );
};

export default SupramaxTable;
